import { navigate } from 'gatsby'
import React from 'react'
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { capitalize } from 'lodash'
import { CacheUser, Order, ProductPageItemType, ResponseType } from '@types'
import { order as graphqlOrder, user } from '@graphql'
import { AvatarCard, GolfClubInfo, Loader, OrderPaymentDetails, OrderPriceList } from '@elements'
import { AccountLayout } from '@layouts'
import { useWindowSize } from '@hooks'
import { Container } from '@components'
import { get12HourFromDate, getGolfClubInfoByCategory, isGolfClubByType, zeroPad } from '@utils'
import BackArrowImage from '@images/back-arrow.svg'
import './details.scss'
import { AccountPage } from '@pages/components'
import { css } from '@emotion/css'

interface AccountOrderHistoryDetailsProps extends RouteComponentProps<{ id: string }> {}

const formatDate = (date: string | Date) => {
  const d = new Date(date)
  const day = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  const time = get12HourFromDate(d)

  return `${month}/${day}/${year} ${time}`
}

const AccountOrderHistoryDetails: React.FC<AccountOrderHistoryDetailsProps> = props => {
  const { id } = props

  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data: userData } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { data, loading } = useQuery<ResponseType<Order>>(graphqlOrder.FetchOrder, {
    variables: { id }
  })

  const currentUser = userData && userData.res
  const addressCard = currentUser?.defaultAddressCard
  const paymentCard = currentUser?.defaultPaymentCard
  const golfTypeId = data?.res?.golfClub?.golfClubType?.id || data?.res?.golfEquipment?.golfEquipmentType?.id

  const order = data && data.res
  const golfItem = order?.golfClub || order?.golfEquipment
  const golfModel = order?.golfEquipment?.golfEquipmentModel || order?.golfClub?.golfClubModel
  const golfType = order?.golfClub?.golfClubType || order?.golfEquipment?.golfEquipmentType
  const isGolfClub = !!order?.golfClub

  const handleEditPaymentDetails = async () => {
    if (golfType) {
      const isGolfClubPage = isGolfClubByType(golfType)
      const itemType: ProductPageItemType = isGolfClubPage ? 'GolfClubModel' : 'GolfEquipmentModel'
      await navigate(`/checkout/${golfTypeId}/payment`, {
        state: { backUrl: location.pathname, type: itemType }
      })
    }
  }

  if (!currentUser) {
    navigate('/home/')
    return null
  }

  if (!loading && !order) navigate('/account/order-history')
  if (!order) return null

  const renderContent = () => {
    if (loading) return <Loader />

    const avatar = golfModel?.avatar || ''
    const title = golfModel?.title || ''
    const formattedDate = formatDate(order.insertedAt)
    const golfClubOptionInfo = getGolfClubInfoByCategory(order?.golfClub?.golfClubType?.golfClubModel?.category)

    if (isMobileScreen) {
      const renderGolfClubOption = () => {
        if (isGolfClub)
          return (
            <>
              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>Flex</strong>
                </div>
                <div>{capitalize(order?.golfClub?.golfClubType?.flex) || '-'}</div>
              </div>

              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>Dexterity</strong>
                </div>
                <div>{capitalize(order?.golfClub?.golfClubType?.dexterity) || '-'}</div>
              </div>
            </>
          )
      }

      return (
        <Container>
          <div className={'account-order-history-details-content account-position'}>
            <p
              className={'text-20 account-order-history-details-back-button'}
              onClick={() => navigate('/account/order-history')}
            >
              <BackArrowImage />
              My Order History
            </p>

            <AccountPage.OrderDelivery
              order={order}
              className={css`
                margin-bottom: 60px;
              `}
            />
            <div className={'account-order-history-details-mobile-info'}>
              <AvatarCard avatar={avatar} />
              <div className={'account-order-history-details-mobile-info-details'}>
                <div className={'account-order-history-details-mobile-info-details-number'}>
                  <strong>ORDER ID: TD{zeroPad(order.number, 6)}</strong>
                </div>
                <div className={'account-order-history-details-mobile-info-details-title'}>
                  <strong>{title}</strong>
                </div>
                <div className={'account-order-history-details-mobile-info-details-date'}>
                  <strong>{formattedDate}</strong>
                </div>
              </div>
            </div>
            <div className={'account-order-history-details-mobile-golf-club-params'}>
              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>Brand</strong>
                </div>
                <div>{capitalize(golfItem?.brand?.title) || '-'}</div>
              </div>
              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>Category</strong>
                </div>
                <div>{capitalize(golfItem?.category?.title) || '-'}</div>
              </div>
              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>Release Year</strong>
                </div>
                <div>{golfModel?.releaseYear || '-'}</div>
              </div>
              {renderGolfClubOption()}
              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>Condition</strong>
                </div>
                <div>{capitalize(golfType?.condition) || '-'}</div>
              </div>
              <div className={'account-order-history-details-mobile-golf-club-params-row'}>
                <div className={'grey uppercase'}>
                  <strong>{golfClubOptionInfo.title}</strong>
                </div>
                <div>{golfType?.option || '-'}</div>
              </div>
            </div>
            <h4>
              <strong className={'uppercase'}>Payment</strong>
            </h4>
            <OrderPriceList addressCardId={addressCard?.id} price={golfItem?.price} />
            <OrderPaymentDetails
              address={addressCard}
              payment={paymentCard}
              onPaymentDetailsEdit={handleEditPaymentDetails}
            />
          </div>
        </Container>
      )
    }

    return (
      <AccountLayout pageClassName={''}>
        <div className={'account-order-history-details-content account-position'}>
          <p
            className={'text-20 account-order-history-details-back-button'}
            onClick={() => navigate('/account/order-history')}
          >
            <BackArrowImage />
            My Order History
          </p>

          <AccountPage.OrderDelivery
            order={order}
            className={css`
              margin-bottom: 40px;
            `}
          />

          <h4>
            <strong>{title}</strong>
          </h4>
          <GolfClubInfo golfType={golfType} />
          <AvatarCard avatar={avatar} />
          <OrderPriceList addressCardId={addressCard?.id} price={golfItem?.price} />
          <OrderPaymentDetails
            address={addressCard}
            payment={paymentCard}
            onPaymentDetailsEdit={handleEditPaymentDetails}
          />
        </div>
      </AccountLayout>
    )
  }

  return <div className={'account-page account-order-history-details'}>{renderContent()}</div>
}

export default AccountOrderHistoryDetails
